.title {
  color: #fb4570;
  font-weight: 800;
  font-size: 2rem;
  letter-spacing: 9px;
  margin-top: 8vmin;
  text-transform: uppercase;
  animation: glow 2s ease-in-out infinite alternate;
  text-align: center;
}

@media screen and (min-width: 440px) {
  /* For mobile phones: */
  .title {
    font-size: 1.5;
  }
}


.show {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 495px) {
  /* For mobile phones: */
  .hide {
    display: none;
  }
}
@media screen and (min-width: 495px) {
  /* For mobile phones: */
  .show {
    display: none;
  }
}


@keyframes glow {
  from {
    text-shadow: 0 0 20px #ff0080;
  }
  to {
    text-shadow: 0 0 30px #FF0D86,  0 0 10px #a91b60;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: white;
  width: 100%;
  text-align: center;

}

li {
  font-size: 18px;
  list-style-type: none;
  list-style-image: none;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  float: none;
  height: auto;
  margin-right: 30px;
  width: auto;
  display: inline-block;
}

li:hover {
  color: #fb4570;
  font-size: 12px;
  cursor: pointer;
}

.horiz-menu li {
  margin-right: 6vmin;
  /* margin-top: 4vmin; */

}

.horiz-menu li{
  color: white;
}

.horiz-menu li:hover{
  color: #fb4570 !important;
  font-weight: 800;
  cursor: pointer;
}

.container1{
  display: flex;
  justify-content: center;
}