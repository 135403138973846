div {
  color: white;
}

.vid-container {
  position: relative;
  z-index: -999;
  margin-top: calc(10% - 30%);
  margin-bottom: calc(20% - 30%);
}
