.read-more-link{
    color:#F90066;
    text-align: center;
    cursor: pointer;
}

.read-more-link:hover{
    color: white
}
.container2{
    margin-bottom: 150px
}

@media screen and (max-width: 500px) {
    /* For mobile phones: */
    .container2 {
      margin-top: -90px
    }
  }