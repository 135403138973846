.container {
  /* position: absolute;
  height: 68px;
  /* clear: both; */
  /* margin-top: calc(8% - 100px); */
  position: fixed;
  background: #fb4570;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-top: 20px;
}

.fa {
  font-size: 30px;
  text-align: center;
  text-decoration: none;
  color: #666666;
}

.social-network-links {
  margin-bottom: calc(-8px);
}

ul {
  /* padding: 40px 0 */
}

li {
  font-size: 0.9em;
  color: black;
}

li:hover {
  font-size: 0.9em;
  color: white;
}

a {
  color: white;
}


/* a:hover{
  color:black
} */