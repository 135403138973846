.container {
  color: white !important;
}

button {
  display: none;
}

.bg-video {
  width: 100%;
  height: 900px;
}
@keyframes mymove {
  50% {
    box-shadow: 0px 20px 30px blue;
  }
}

@media screen and (max-width: 650px) {
  /* For mobile phones: */
  .bg-video {
    height: 100vmin;
  }
}

a {
  color: inherit;
  text-decoration: none;
  letter-spacing: 4px;
}

.vid-container {
  margin-top: calc(10% - 24%);
  margin-bottom: calc(20% - 34%);
  position: relative;
  width: 100%;
  z-index: -9999999;
}

.vid-container:hover {
  width: 100%;
  cursor: pointer;
  opacity: 0.3;
}

/* .text {
  background-color: #EB0189;
  color: white;
  font-size: 90px;
  margin-top: calc(10% - 70%);
  margin-bottom: calc(60% - 10%);
  position: relative;
  width:100%;
  z-index: 99999;
  width: 100%;
} */

.moreCTA {
  background: black;
}

@media screen and (min-width: 650px) {
  /* For mobile phones: */
  .moreCTA {
    display: none;
  }
}

@media screen and (min-width: 375px) {
  /* For mobile phones: */
  .moreCTA {
    margin-top: -50px;
  }
}

@media screen and (min-height: 812px) {
  /* For mobile phones: */
  .moreCTA {
    margin-top: 60px;
  }
}
