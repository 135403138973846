div {
  color: white;
}

img {
  margin-top: 50px;
  margin-bottom: 70px;
  height: 100%;
  width: 100%;
}
